<template>
    <v-container>
        <h4 class="headline mt-5 text-center primary--text"><strong>{{hotelBranchId.name}}</strong></h4>

        <!--<v-row class="ml-1">-->
        <!--<h4>Hotel Branch ID is {{$route.params.hotelID}}</h4>-->
        <!--<v-col cols="12" sm="12">
        <v-card elevation="5">
            <template>-->
        <!--<v-sheet color="white" height="80%" align="center" tile>
        <v-row class="fill-height" align="center" justify="center">
            <div class="grey--text">
                <img :src="'/'+hotelBranchId.logo" style="width: 100%; height: 200px; object-fit: fill; border: 3px solid black;" />
                <img :src="'/'+hotelBranchId.logo" style="width: 100%; height: 550px; object-fit: fill;" />-->
        <!--<img :src="'/'+hotelBranchId.logo" alt="src" />-->
        <!--</div>
        </v-row>
    </v-sheet>-->
        <!--</template>
            </v-card>
        </v-col>
    </v-row>--> 

        <div>
            <v-row>
                <v-col cols="12" sm="12">
                    <v-card class="mt-2"
                            id="slider-card"
                            max-height="550"
                            width="100%"
                            :elevation="24"
                            style="background:white">

                        <img :src="'/'+hotelBranchId.logo" style="width: 100%; height: 550px; object-fit: fill;" />
                    </v-card>
                </v-col>
            </v-row>
        </div>



        <v-container>

            <h4 class="headline mt-10 text-center"> {{hotelBranchId.name}} {{$t('customers')}}</h4>
            <v-row>
                <v-col cols="12" md="12" class="mt-5">
                    <v-card elevation="5">
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="barsData"
                                        :options="chartOptions"
                                        style="height: 400px; " />
                            </div>
                        </template>
                    </v-card>

                </v-col>

            </v-row>


        </v-container>
        <hr style="margin-top: 60px;" />
        <v-row>
            <v-col cols="12" md="6">
                <h1 class="headline mt-5 primary--text" style="margin-left:20px">{{hotelBranchId.name}} {{$t('customers')}}</h1>
            </v-col>
            <v-col cols="12" md="6">
                <h1 class="headline mt-5 primary--text">{{hotelBranchId.name}} {{$t('employee')}}</h1>
            </v-col>
        </v-row>


        <v-container>


            <v-row>

                <v-col cols="12" md="6">
                    <v-card>
                        <v-list style="background-color: #077dfa">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-title class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                </v-list-item-avatar>
                                <v-list-item-content align="center">
                                    <v-list-item-title class="white--text"><strong> {{$t('customer_name')}}</strong> </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="center">
                                    <v-list-item-title class=" white--text"><strong>{{$t('table.room_number')}}</strong></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-content align="center">
                                    <v-list-item-title class=" white--text"><strong>{{$t('check_in_date')}}</strong></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="white--text"><strong>{{$t('check_out_date')}}</strong></v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-card style="overflow-y: scroll; height: 350px;">

                        <v-list v-for="(hotelCheckInOut, i) in hotelCustomerCheckInOut"
                                :key="i">
                            <v-list-item :style="{background: hotelCheckInOut.backgroundColor }">
                                <v-list-item-avatar>
                                    <v-title class="black--text">{{i+1}}</v-title>
                                </v-list-item-avatar>
                                <v-list-item-content align="center">
                                    <v-list-item-title class="black--text">{{hotelCheckInOut.fullName}} </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-content align="center">
                                    <v-list-item-title class="mr-18 black--text"> {{hotelCheckInOut.roomNumber}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="center">
                                    <v-list-item-title class="mr-15 black--text"> {{hotelCheckInOut.checkInDate}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="black--text"> {{hotelCheckInOut.checkOutDate}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                        </v-list>



                    </v-card>
                    <!--<button @click="$router.push('/hotel')"  class=" white--text primary " dark style="padding:5px; border-radius:5px; margin-top:10px">For More Details</button>-->
                </v-col>

                <v-col cols="12" md="6">
                    <v-card>
                        <v-list style="background-color: #077dfa">

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-title class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title align="center" class="white--text"><strong>{{$t('employee_name')}}</strong> </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-content align="center">
                                    <v-list-item-title class="mr-18 white--text"><strong>{{$t('email')}}</strong></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="center">
                                    <v-list-item-title class="mr-15 white--text"><strong> {{$t('position')}}</strong></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content align="right">
                                    <v-list-item-title class="white--text"><strong> {{$t('contact_no')}}</strong></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>



                    </v-card>

                    <v-card style="overflow-y: scroll; height: 350px">
                        <v-card style="overflow-y: scroll; height: 350px;">

                            <v-list v-for="(hotelEmployee, i) in hotelEmployeeDetails"
                                    :key="i">
                                <v-list-item :style="{background: hotelEmployee.backgroundColor }">
                                    <v-list-item-avatar>
                                        <v-title class="black--text">{{i+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title align="center" class="black--text">{{hotelEmployee.empName}}</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-content align="center">
                                        <v-list-item-title class="mr-18 black--text">{{hotelEmployee.empEmail}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="center">
                                        <v-list-item-title class="mr-15 black--text"> {{hotelEmployee.empType}}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title class="black--text">{{hotelEmployee.empPhone}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>



                        </v-card>
                    </v-card>
                    <!--<button @click="$router.push('/hotelEmployee')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                </v-col>

            </v-row>

        </v-container>

        <v-container>

            <h4 class="headline md-10 text-center">
                {{hotelBranchId.name}} {{$t('monthly_customers')}}
            </h4>
            <v-row>
                <v-col cols="12" md="12" class="mt-5">
                    <template>
                        <v-card elevation="5">
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="customerData"
                                        :options="chartOptions"
                                        style="height: 400px;" />
                            </div>
                        </v-card>

                    </template>
                </v-col>



                <!--<v-col cols="12" md="6" style=" margin-top: 40px">
            <v-card>
                <template>
                    <v-content>
                        <available></available>-->
                <!--<v-row justify="start">

                <div class="px-4" v-for="roomData in roomDatas" :key="roomData.roomID">
                    <v-card width="200">

                        <v-card-title class="pas-title-card">{{roomData[0].category}}</v-card-title>
                        <v-row>
                            <v-col v-for="room in roomData" :key="room.roomID">
                                <div :class="{
                                          'red-custom':room.roomStatus,
                                          'green-custom': !room.roomStatus
                                      }">
                                    {{room.roomNumber}}
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                </div>
            </v-row>-->
                <!--</v-content>
                    </template>
                </v-card>
            </v-col>-->



            </v-row>

        </v-container>
        <v-row class="my-5">
            <hr style="margin-top: 60px; width:100% " />
            <v-col cols="12" md="12">
                <h1 class="headline mb-2 primary--text">{{hotelBranchId.name}} {{$t('room')}}</h1>
                <v-card elevation="5">
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="RoomData"
                                    :options="chartOptions"
                                    style="height: 350px" />
                        </div>
                    </template>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mdiClock, mdiStore, mdiNaturePeople, mdiHead } from "@mdi/js";
    /*    import VueApexCharts from "vue-apexcharts";*/
    /*    import axios from "axios";*/
    /*import available from "../HotelRoom/Available.vue"*/

    import publicAxios from '../../../simple-axios'
    // import Vue from 'vue/types/umd';
    // Vue.use(VueApexCharts)

    export default {
        props: [
            "btn-text"
        ],
        watch: {},


        data: () => ({
            hotelBranchId: [],
            hotelCustomerCheckInOut: [],
            hotelEmployeeDetails: [],




            //roomDatas: [],
            //branchInformation: [],
            chartData: [],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },
            barData: [],
            barsData: [],

            customerData: [],

            RoomData: [],
            slides: [
                "Online check and payment of official debts",
                "Online check and payment of official ",
                "Online check and payment of ",
                "Online check and payment ",
            ],

            series: [400, 450, 350, 310, 280, 330, 520, 120, 130, 250],
            series1: [50, 30, 35, 20, 12],
            chartsOptions: {
                chart: {
                    width: 800,
                    type: "donut",
                },
                labels: [
                    "Kitchen Staff",
                    "Room Service",
                    "Waiter/Waitress",
                    "Accounting",
                    "Housekeeping",
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 500,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            slide: [
                "../assets/soltihotel.jpg",
                "../assets/hyatthotel.jpg",
                "../assets/YakandYeti.jpg",
            ],
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHead
        }),
        components: {
            /*apexchart: VueApexCharts,*/
            /*available*/
        },
        methods: {
            async getHotelBranchId() {

                const { data } = await publicAxios.post("HotelDetails/GetIndividualHotelDetails/" + this.$route.params.hotelID)

                this.hotelBranchId = data
                console.log('this.hotelBranchData', this.hotelBranchId)
                //const { Individuldata } = await publicAxios.post('HotelDetails/GetIndividualHotelDetails?BranchID')
                //this.IndividulData = Individuldata;
            },
            async getBranchCustomerCheckInOut() {

                const { data } = await publicAxios.get("HotelDetails/GetBranchHotelCustomerCheckInOut/" + this.$route.params.hotelID)

                this.hotelCustomerCheckInOut = data
                console.log('this.hotelCustomerCheckInOut', this.hotelCustomerCheckInOut)

            },
            async getBranchEmployeeDetails() {

                const { data } = await publicAxios.post("HotelDetails/GetHotelEmployeeDetails/" + this.$route.params.hotelID)

                this.hotelEmployeeDetails = data
                console.log('this.hotelEmployeeDetails', this.hotelEmployeeDetails)

            },

            async getHotelCustomerCountryWise() {
                const { data } = await publicAxios.get("HotelDetails/GetBranchHotelCustomerCountrywisecountAsync/" + this.$route.params.hotelID)
                let formatedData = this.getChartData(data)
                this.barsData = formatedData
                console.log('this.HotelBranchCustomerCountrywisecountAsync', this.barsData)
            },
            async getHotelCustomerCountBar() {
                const { data } = await publicAxios.get("HotelDetails/GetHotelCustomerMonthWise/" + this.$route.params.hotelID)
                let formatedData = this.getChartData(data)
                this.customerData = formatedData
                console.log('this.HotelBranchCustomerCountBar', this.customerData)
            },
            async getHotelRoomTotalBookedCount() {
                const { data } = await publicAxios.post("HotelDetails/GetHotelRoomStatusMonthWise/" + this.$route.params.hotelID)
                let formatedData = this.getChartData(data)
                this.RoomData = formatedData
                console.log('this.HotelRoomTotalBookedCount', this.RoomData)
            },

            //async formData() {
            //    const dashboardData = await axios.get(
            //        "Branch/GetBranchInformationByBranchID" + this.$route.params.hotelID
            //    );
            //    this.branchInformation = dashboardData.data;
            //    console.log('this.branchInformation', this.branchInformation)

            //    const availableData = await axios.get('Hotel/RoomStatusCountAsync', {
            //        params: {
            //            ShowCount: false
            //        }
            //    })
            //    this.roomDatas = availableData.data

            //    console.log('this.roomMainData',this.roomDatas)

            //},
            //async formData() {
            //    const dashboardData = await axios.get(
            //        "Branch/GetBranchInformationByBranchID"
            //    );
            //    this.branchInformation = dashboardData.data;
            //    console.log('this.BranchInformationByBranchID', this.branchInformation)

            //    const availableData = await axios.get('Hotel/RoomStatusCountAsync', {
            //        params: {
            //            ShowCount: false
            //        }
            //    })
            //    this.roomDatas = availableData.data
            //    console.log('roomData', this.roomDatas)

            //},customerData:


            getChartData(data) {
                let arr = []
                arr[0] = Object.keys(data[0])
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1])
                }
                return arr;
            }



        },
        mounted() {
            this.formData()
        },
        created() {
            this.getHotelBranchId()
            this.getHotelCustomerCountryWise()
            this.getBranchCustomerCheckInOut()
            this.getBranchEmployeeDetails()
            this.getHotelCustomerCountBar()
            this.getHotelRoomTotalBookedCount()

        },
        computed: {

        }


        //methods: {
        //    async getHotelData() {
        //        const HotelData = await publicAxios.get('HotelDetails/GetAllHotelDetailsList')
        //        this.HotelData = HotelData
        //        console.log('this.hotelData', this.HotelData)
        //        /*console.log('this.hotelData', this.HotelData)*/
        //    },
        //},
        //created() {
        //    this.getHotelData()
        //}
    };
</script>
<style scoped>
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

    .v-progress-circular {
        margin: 1rem;
    }

    a {
        text-decoration: none;
    }

    .pas-icon {
        font-size: 4em !important;
    }

    .pas-title-card {
        text-transform: uppercase;
        background: #1976d2;
        color: white;
    }

    .pas-icon-available {
        color: green;
        font-size: 4em !important;
    }

    .green-custom {
        background-color: #6de6d6b3;
    }

    .red-custom {
        background-color: #ffbdbd;
    }
</style>
